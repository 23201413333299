import { Network } from '../config/types';

// MINT //

export const price = 0.125;
export const messageToSign = `Please sign this message to mint Meta Angels!`;

// Feb 8 12 pm EST
export const preSaleStartDate = new Date(Date.UTC(2022, 1, 10, 18, 0, 0, 0));

// Feb 10 12 pm EST
export const preSaleEndDate = new Date(Date.UTC(2022, 1, 28, 17, 0, 0, 0));

// Dec 11 12 pm EST (this needs to change)
export const publicSaleStartDate = new Date(Date.UTC(2022, 11, 11, 17, 0, 0, 0));

export const totalMintSupply = 10000;

export const isEveryoneAllowedToMint = false;
export const everyAllowedToMintMax = 3;

export const loanEnabled = true;
export const teamWallets = [
  '0xaaaf8855eced11cd10709f82e408cfd2caaf82a4',
  '0x63c2e0b23e8942a6100d34aac26f6adf9cba69f5',
  '0xc88df4312bab3d83570b928a7270ff01a20ed85d',
  '0x97b640055aa506152b1ef39539674a55726390b9',
  '0x718a3aa8f5c435102c87040b01c609a297fa12c5',
];

export const whitelistedWallets = [
  '0x956c6d339e792f31079d4d368d4d066394fe3607',
  '0x886478d3cf9581b624cb35b5446693fc8a58b787',
  '0xe6402e201562a5487e76ce2e149ea7ad330885da',
  '0xe450637a5b515d5bb09d2b75cb4ca89700cc410c',
  '0x16ad4e68c2e1d312c01098d3e1cfc633b90dff46',
  '0xf003c7540eb55057e95bad50d7d8c55d327dc571',
  '0x8c95654da82f79f4c5c7cdbf8dc2fe3f9af6e921',
];

// Adobe AIR //

export const adobeAirMintingEnabled = false;
export const adobeAirMintToken = 12;
export const adobeAirMintEnd = 'June 7th, 2023 at 7pm EST'

// PRINT //

export const downloadSignatureMessage = 'Meta Angels Holder verification';

export const testNetwork: Network = {
  chainId: '4',
  networkOfficialName: 'rinkeby',
  networkName: 'Rinkeby',
  infuraProjectId: '6e4e3717991d4db79e704c2e3932f9f9',
  infuraProjectUrl: 'https://rinkeby.infura.io/v3/6e4e3717991d4db79e704c2e3932f9f9',
};

export const mainNetwork: Network = {
  chainId: '1',
  networkOfficialName: 'mainnet',
  networkName: 'Ethereum Mainnet',
  infuraProjectId: 'f4819e76337749c3be8bb76e39073df6',
  infuraProjectUrl: 'https://mainnet.infura.io/v3/f4819e76337749c3be8bb76e39073df6',
};

export const polygonMainNetwork: Network = {
  chainId: '137',
  networkOfficialName: 'matic',
  networkName: 'Polygon Mainnet',
  infuraProjectId: 'f4819e76337749c3be8bb76e39073df6',
  infuraProjectUrl: 'https://polygon-mainnet.infura.io/v3/f4819e76337749c3be8bb76e39073df6',
};

export const polygonMumbaiNetwork: Network = {
  chainId: '80001',
  networkOfficialName: 'maticmum',
  networkName: 'Mumbai',
  infuraProjectId: '6e4e3717991d4db79e704c2e3932f9f9',
  infuraProjectUrl: 'https://polygon-mumbai.infura.io/v3/6e4e3717991d4db79e704c2e3932f9f9',
};

export const rinkebyEtherscanTxUri = 'https://rinkeby.etherscan.io/tx/';
export const mainnetEtherscanTxUri = 'https://etherscan.io/tx/';
export const maticMainnetPolyscanTxUri = 'https://polygonscan.com/tx/';
export const mumbaiPolyscanTxUri = 'https://mumbai.polygonscan.com/tx/';

export const rinkebyOpenSeaBaseUrl = 'https://testnets.opensea.io/';
export const mainnetOpenSeaBaseUrl = 'https://opensea.io/';
