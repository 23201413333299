import React from 'react';
import { default as NextHead } from 'next/head';

type HeadProps = {
  title: string;
  metaContent: string;
  image?: string;
};
const Head = ({ title, metaContent, image }: HeadProps) => {
  return (
    <NextHead>
      <title>{title}</title>
      <link rel="stylesheet" href="https://use.typekit.net/lry5yla.css"></link>
      <meta name="description" content={metaContent} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaContent} />
      <meta property="og:site_name" content="Meta Angels NFT" />
      <meta property="og:image" content={image || "/meta-new-logo.png"} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@meta_angels" />
      <meta property="twitter:description" content={metaContent} />
      <meta name="twitter:image" content="/meta-new-logo.png" />

      <link rel="icon" href="/favicon.ico" />
    </NextHead>
  );
};

export default Head;
