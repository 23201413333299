import React from 'react';

import CtaButton, { theme } from './cta-button';

const DiscordCta: React.FC<{ theme?: theme }> = ({ theme }) => {
  return (
    <CtaButton theme={theme} href="https://discord.gg/WjxgsTBqXy">
      Join our Discord
    </CtaButton>
  );
};

export default DiscordCta;
