import classNames from 'classnames';
import DiscordCta from './cta/discord-cta';

import styles from '../styles/modules/Footer.module.scss';

const Footer = () => {
  return (
    <footer className="justify-end" style={{ marginTop: 'auto' }}>
      <div
        className="bg-black text-white"
        style={{
          paddingTop: '3.3vmax',
          paddingBottom: '3.3vmax',
          paddingRight: '6vw',
          paddingLeft: '6vw',
        }}
      >
        <div
          style={{
            paddingBottom: '17px',
            fontSize: 'calc((1.6 - 1) * 1.2vh + 1rem)',
            fontFamily: 'acumin-pro, sans-serif',
          }}
        >
          Join the Movement
        </div>
        <span className="md:hidden inline-block float-none mb-5 p-10 ml-10">
          <DiscordCta theme="light" />
        </span>
        <span className="md:inline-block hidden float-right p-10 ml-10">
          <DiscordCta theme="light" />
        </span>
        <div>
          We’re building a community of problem solvers who want to roll up
          their sleeves and help others. Our Discord community is open to all as
          we prepare for launch. Crypto/NFT newbies welcome, we’ve got expertise
          ready to help you explore and learn about the metaverse.
        </div>
        <div className={classNames(styles.footLinks, "whitespace-pre-wrap mt-5")}>
          <a
            className="break-words"
            href="https://www.metaangelsnft.com/contact"
          >
            Contact us!
          </a>
          <a
            className="break-words ml-20"
            href="https://www.metaangelsnft.com/terms-of-use"
          >
            Terms of Use
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
