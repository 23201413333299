import classNames from 'classnames';
import CtaButton from './cta/cta-button';
import React from 'react';

import styles from '../styles/modules/Header.module.scss';

const Header = () => {
  return (
    <div
      className={classNames(
        styles.headerContainer,
        'flex flex-row items-center'
      )}
    >
      <div
        className={classNames(
          styles.headerContainerLeft,
          'flex flex-row items-center'
        )}
      >
        <div className={classNames(styles.logoContainer)}>
          <a
            href="https://www.metaangelsnft.com/"
            className={classNames(styles.logo)}
          >
            <img
              alt="Meta Angels logo"
              style={{ maxHeight: '124px ' }}
              src="/meta-new-logo.png"
            />
          </a>
        </div>
        <div className="justify-end text-right ml-auto hidden lg:inline-flex">
          <div className={classNames(styles.headerLinks)}>
            <a href="https://www.metaangelsnft.com/benefits-overview">Membership Benefits</a>
          </div>
          <div className={classNames(styles.headerLinks)}>
            <a href="https://explore.metaangelsnft.com/">Marketplace</a>
          </div>
          <div className={classNames(styles.headerLinks)}>
            <a href="https://www.metaangelsnft.com/lending">Lending Program</a>
          </div>
          <div className={classNames(styles.headerLinks)}>
            <a href="https://discord.gg/nYcdG2d2W7">Discord</a>
          </div>
        </div>
      </div>
      <div className={classNames(styles.headerContainerRight)}>
        <CtaButton href="/profile">Member Login</CtaButton>
      </div>
    </div>
  );
};

export default Header;
