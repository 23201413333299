import React from 'react';
import Loader from 'react-loader-spinner';
import classNames from 'classnames';

import styles from '../styles/modules/Modal.module.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

type ModalProps = {
  message: React.ReactElement;
  showLoader?: boolean;
  onClose?: () => void;
};

const Modal = ({ message, showLoader, onClose }: ModalProps) => {
  return (
    <div
      onClick={(e) => {
        onClose && onClose();
      }}
      className={styles.modal}
    >
      <div
        className={classNames(
          styles.modalContent,
          'text-center flex flex-col items-center'
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="text-large font-bold mb-2">{message}</div>
        {showLoader && (
          <div className="text-center m-auto">
            <Loader
              type="BallTriangle"
              timeout={300000}
              color="#08089a"
              height={50}
              width={50}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
