import axios from 'axios';
import Web3Modal from 'web3modal';
import { backOff } from 'exponential-backoff';
import { Contract, Signer, BigNumber, ethers, providers, utils } from 'ethers';
import {
  price,
  testNetwork,
  mainNetwork,
  polygonMainNetwork,
  polygonMumbaiNetwork,
  mainnetEtherscanTxUri,
  rinkebyEtherscanTxUri,
  maticMainnetPolyscanTxUri,
  mumbaiPolyscanTxUri,
  rinkebyOpenSeaBaseUrl,
  mainnetOpenSeaBaseUrl,
} from './constants';
import { DeployEnvironment, Network } from '../config/types';
import contractABI from '../contract-abi.json';
import { Status, StatusType } from '../config/types';

declare let window: any;

let provider: providers.Web3Provider;
let contract: Contract;
let signer: Signer;
let contractAddress: string;
let etherscanTxUri: string;
let polygonscanTxUri: string;
let ethereumNetwork: Network;
let polygonNetwork: Network;

export const zeroAddress = '0x0000000000000000000000000000000000000000';

export const isAddress = utils.isAddress;

export const fetchContract = async () => {
  if (!provider || !contract || !signer) {
    if (!ethereumNetwork) {
      await fetchNetworkAndEtherscanUri();
    }

    const contractProvider =
      provider ||
      new ethers.providers.JsonRpcProvider(ethereumNetwork.infuraProjectUrl);
    contract = new ethers.Contract(
      contractAddress,
      contractABI,
      contractProvider
    );
  }
};

export const fetchNetworkAndEtherscanUri = async (): Promise<
  [Network, string, string]
> => {
  const result = await backOff(() =>
    axios.get(`${window.location.origin}/api/constants`)
  );

  contractAddress = result.data.data.contractAddress;

  return determineNetworkAndEtherscanUri(result.data.data.deployEnvironment);
};

export const determineNetworkAndEtherscanUri = (
  deployEnvironment: DeployEnvironment
): [Network, string, string] => {
  const isProdEnvironment = deployEnvironment === DeployEnvironment.prod;
  ethereumNetwork = isProdEnvironment ? mainNetwork : testNetwork;
  etherscanTxUri = isProdEnvironment
    ? mainnetEtherscanTxUri
    : rinkebyEtherscanTxUri;
  const openSeaBaseUri = isProdEnvironment
    ? mainnetOpenSeaBaseUrl
    : rinkebyOpenSeaBaseUrl;

  return [ethereumNetwork, etherscanTxUri, openSeaBaseUri];
};

export const determinePolygonNetworkAndPolygonscanUri = (
  deployEnvironment: DeployEnvironment
): [Network, string, string] => {
  const isProdEnvironment = deployEnvironment === DeployEnvironment.prod;
  // When testing, mumbai doesn't have a working tank so we need to use the mainnet
  // so toggle the line below
  polygonNetwork = polygonMainNetwork;
  // polygonNetwork = isProdEnvironment ? polygonMainNetwork : polygonMumbaiNetwork;
  polygonscanTxUri = isProdEnvironment
    ? maticMainnetPolyscanTxUri
    : mumbaiPolyscanTxUri;
  const openSeaBaseUri = isProdEnvironment
    ? mainnetOpenSeaBaseUrl
    : rinkebyOpenSeaBaseUrl;

  return [polygonNetwork, polygonscanTxUri, openSeaBaseUri];
};

export const checkTotalSupply = async (): Promise<number> => {
  try {
    await fetchContract();
    const totalSupplyBN: BigNumber = await contract.totalSupply();
    const totalSupply: number = totalSupplyBN?.toNumber();
    return totalSupply;
  } catch (error) {
    console.log(error);
    return 0;
  }
};

export const checkSaleActive = async (): Promise<boolean | undefined> => {
  try {
    await fetchContract();
    const isSaleActive: boolean = await contract.isSaleActive();
    return isSaleActive;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const ownerOfLoandedToken = async (token: string): Promise<string> => {
  try {
    await fetchContract();
    const originalOwnerAddress: string = await contract.tokenOwnersOnLoan(
      token
    );

    return originalOwnerAddress;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const ownerOfToken = async (token: string): Promise<string> => {
  try {
    await fetchContract();
    const ownerAddress: string = await contract.ownerOf(token);

    return ownerAddress;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const checkTokensForOwner = async (
  address: string
): Promise<string[] | undefined> => {
  try {
    await fetchContract();
    const ownedTokensBN: BigNumber[] = await contract.ownedTokensByAddress(
      address
    );
    const tokens = ownedTokensBN.map((token) => token.toString());
    return tokens;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const checkLoanedTokensForOwner = async (
  address: string
): Promise<string[] | undefined> => {
  try {
    await fetchContract();
    const ownedTokensBN: BigNumber[] = await contract.loanedTokensByAddress(
      address
    );
    const tokens = ownedTokensBN.map((token) => token.toString());
    return tokens;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const checkTotalMintsPerAddress = async (
  address: string
): Promise<number | undefined> => {
  try {
    const totalMintsPerAddressBN: BigNumber =
      await contract.totalMintsPerAddress(address);
    const totalMintsPerAddress: number = totalMintsPerAddressBN?.toNumber();
    return totalMintsPerAddress;
  } catch (error) {
    console.log(error);
    // TODO (by, 2021-10-31) Return a number so high they can't mint for now - should show them an error
    return;
  }
};

export const retrieveToken = async (token: string): Promise<string> => {
  const gas = await contract.connect(signer).estimateGas.retrieveLoan(token);
  try {
    const { hash: txHash } = await contract
      .connect(signer)
      .retrieveLoan(token, {
        gasLimit: gas.mul(12).div(10),
      });
    return txHash;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const isPotentiallyContract = async (
  address: string
): Promise<boolean> => {
  const code = await provider.getCode(address);

  return code !== '0x';
};

export const loanToken = async (
  token: string,
  addressToLoanTo: string
): Promise<string> => {
  const gas = await contract
    .connect(signer)
    .estimateGas.loan(token, addressToLoanTo);
  try {
    const { hash: txHash } = await contract
      .connect(signer)
      .loan(token, addressToLoanTo, {
        gasLimit: gas.mul(12).div(10),
      });
    return txHash;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const mintNFT = async (
  mintNumber: number,
  maximumAllowedMints: number,
  messageHash: string,
  signature: string
): Promise<string> => {
  const amount: BigNumber = ethers.utils
    .parseEther(price.toString())
    .mul(mintNumber);
  const gas = await contract
    .connect(signer)
    .estimateGas.mint(messageHash, signature, mintNumber, maximumAllowedMints, {
      value: amount,
    });
  try {
    const { hash: txHash } = await contract
      .connect(signer)
      .mint(messageHash, signature, mintNumber, maximumAllowedMints, {
        value: amount,
        gasLimit: gas.mul(12).div(10),
      });
    return txHash;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const checkTransaction = async (txHash: string): Promise<Status> => {
  return new Promise((resolve) => {
    const interval = setInterval(async () => {
      const transaction = await provider.getTransactionReceipt(txHash);
      if (transaction) {
        clearInterval(interval);
        if (transaction.status) {
          resolve({
            statusMessage: (
              <div className="text-left">
                Transaction Successful! Check your transaction on{' '}
                <a
                  className="underline font-semibold"
                  target="_blank"
                  rel="noreferrer"
                  href={etherscanTxUri + txHash}
                >
                  <span className="underline font-semibold">Etherscan</span>
                </a>
              </div>
            ),
            statusType: StatusType.success,
          });
        } else {
          resolve({
            statusMessage: (
              <div className="text-left">
                Your transaction was not successful. See your transaction on{' '}
                <a
                  className="underline font-semibold"
                  target="_blank"
                  rel="noreferrer"
                  href={etherscanTxUri + txHash}
                >
                  <span className="underline font-semibold">Etherscan</span>
                </a>
              </div>
            ),
            statusType: StatusType.error,
          });
        }
      }
    }, 5000);
  });
};

const installMetamask = {
  address: '',
  type: StatusType.warn,
  status: (
    <span>
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://metamask.io/download.html`}
      >
        You must install Metamask, a virtual Ethereum wallet, in your browser.
      </a>
    </span>
  ),
};

export const signMessage = async (messageToSign: string) => {
  const timestamp = Date.now().toString();
  const signature = await signer.signMessage(
    `${messageToSign}. Timestamp: ${timestamp}`
  );
  return [signature, timestamp];
};

export const connectModalWallet = async (
  web3Modal: Web3Modal,
  injectedProviderName: string | null,
  shouldForceConnect: boolean
) => {
  let isAlreadyConnected = false;
  // Test if we can connet to MM w/o having to open the modal
  if (
    (web3Modal.cachedProvider === 'injected' ||
      web3Modal.cachedProvider === 'MetaMask') &&
    injectedProviderName === 'MetaMask'
  ) {
    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
    if (accounts.length) {
      isAlreadyConnected = true;
    }
  }

  if (!isAlreadyConnected && !shouldForceConnect) {
    return;
  }

  const web3Provider = await web3Modal.connect();
  provider = new providers.Web3Provider(web3Provider);
  signer = provider.getSigner();

  web3Provider.on('disconnect', () => {
    web3Modal.clearCachedProvider();
  });
};

export const getCurrentWalletConnected = async () => {
  if (signer) {
    const signerNetworkId = await signer.getChainId();
    if (ethereumNetwork.chainId !== `${signerNetworkId}`) {
      return {
        type: StatusType.error,
        status: (
          <span>{`Wrong network. Please switch to ${ethereumNetwork.networkName} and reload`}</span>
        ),
      };
    }
    const address = await signer.getAddress();
    return {
      type: StatusType.success,
      address: address,
      status: null,
    };
  }

  return null;
};

export const adminWithdrawFunds = async () => {
  try {
    const { hash: txHash } = await contract
      .connect(signer)
      .withdrawFundsAfterRefundExpires();
    return txHash;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const adminSetBaseURI = async (newURI: string) => {
  try {
    const { hash: txHash } = await contract.connect(signer).setBaseURI(newURI);
    return txHash;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const adminSetPreSaleState = async (newState: boolean) => {
  try {
    const { hash: txHash } = await contract
      .connect(signer)
      .setFirstSaleState(newState);
    return txHash;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const adminSetSignerAddress = async (address: string) => {
  try {
    const { hash: txHash } = await contract
      .connect(signer)
      .setSignerAddress(address);
    return txHash;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const adminTransferOwnership = async (address: string) => {
  try {
    const { hash: txHash } = await contract
      .connect(signer)
      .transferOwnership(address);
    return txHash;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const adminGift = async (addresses: string[], mintNumber: number) => {
  try {
    const { hash: txHash } = await contract
      .connect(signer)
      .gift(addresses, mintNumber);
    return txHash;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const adminPause = async () => {
  try {
    const { hash: txHash } = await contract.connect(signer).pause();
    return txHash;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const adminUnpause = async () => {
  try {
    const { hash: txHash } = await contract.connect(signer).unpause();
    return txHash;
  } catch (error) {
    console.log(error);
    return '';
  }
};
