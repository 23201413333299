import React from 'react';
import classNames from 'classnames';

import styles from '../../styles/modules/Cta.module.scss';

export type theme = 'dark' | 'light';

const CtaButton: React.FC<{ href: string; theme?: theme }> = ({
  children,
  href,
  theme = 'dark',
}) => {
  return (
    <div className={classNames(styles.headerContainerRight)}>
      <a className={classNames(styles.cta, styles[theme])} href={href}>
        {children}
      </a>
    </div>
  );
};

export default CtaButton;
