export type WhitelistAddress = {
  address: string;
  mintsAllowed: number;
  list: string;
};

export type WhitelistAddressSigned = {
  address: string;
  list?: string;
  mintsAllowed: number;
  messageHash: string;
  signature: string;
};

export enum DeployEnvironment {
  prod = 'prod',
  dev = 'dev',
}

export type Network = {
  chainId: string;
  networkOfficialName: string;
  networkName: string;
  infuraProjectId: string;
  infuraProjectUrl: string;
};

export enum StatusType {
  none,
  success,
  warn,
  error,
}

export type Status = {
  statusMessage: React.ReactElement | null;
  statusType: StatusType;
};

export type NetworkAndEtherscanUri = {
  network: Network;
  etherscanUri: string;
};

export type MetaAngelAttributes = {
  trait_type: string;
  value: string;
  max_value?: number;
}

export type MetaAngelToken = {
  name: string;
  description: string;
  image: string;
  dna: string;
  edition: number;
  date: number;
  attributes: MetaAngelAttributes[];
  angel_type: string;
  score: number;
}
